import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const AiNectarIcon = (props) => {
    return (
      <svg viewBox="0 0 48 48" fill="none">
      <g clip-path="url(#clip0_409_165678)">
      <rect x="24" width="24" height="48" fill="#D6BBFB"/>
      <path d="M24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0V48Z" fill="#FFCC29"/>
      <path d="M36 24C36 30.6274 30.6274 36 24 36C17.3726 36 12 30.6274 12 24C12 17.3726 17.3726 12 24 12C30.6274 12 36 17.3726 36 24Z" fill="white"/>
      </g>
      </svg>
    )
}

export default AiNectarIcon